.App {
  text-align: center;
}

body {
  background: #fafafa;
}

.box {
  width: 1000px;
  margin: auto;
  /*box-shadow: 0px 0px 20px #eee;*/
  padding: 50px;
  border-radius: 10px;
  padding-top: 70px;
}

.thumb {
  width: calc(50% - 33px);
  margin-right: 10px;
  display: inline-block;
  box-shadow: 0px 0px 5px #ddd;
  border-radius: 5px;
  color: #000;
  text-decoration: none;
  margin-bottom: 10px;
  padding: 10px;
  background: #fff;
  position: relative;
}

.thumbHeader {
  font-size: 18px;
  padding: 10px;
}

.thumbHeaderSmaller {
  width: calc(100% - 120px);
}

.colorBox {
  background: linear-gradient(45deg, #d66516 0%, #94bf1e 100%);
  color: #fff;
  font-size: 11px;
  font-weight: bold;
  border-radius: 10px;
  padding: 5px;
  position: absolute;
  right: 10px;
  top: 20px;
  width: 90px;
  text-align: center;
}

.thumbFooter {
  font-size: 12px;
  padding: 10px;
  background: #eee;
  border-radius: 5px;
}

.thumbSmall {
  width: calc(33% - 29px);
}

.thumbLeft {
  text-align: left;
  width: 50%;
  display: inline-block;
}

.thumbRight {
  text-align: right;
  width: 50%;
  display: inline-block;
}

input.search {
  width: calc(100% - 50px);
  font-size: 16px;
  background: #eee;
  padding: 10px;
  padding-left: 20px;
  border-radius: 25px;
  outline: none;
  border: none;
  height: 30px;
}

div.search {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
  text-decoration: none;
  color: #000;
}

h1.tag {
  padding-bottom: 10px;
    border-bottom: 10px solid transparent;
    border-image: linear-gradient(0.25turn, #d66516, #94bf1e);
    border-image-slice: 1;
    width:100%;
    margin-top: 0px;
}

div.searchBox {
    margin-top: 10px;
    margin-left: 10px;
    /*border-width: 5px;
    border-style: solid;
    border-image:
      linear-gradient(
        to bottom,
        #94bf1e,
        #d66516
      ) 1 100%;
    border-right: none;*/
}

div.tag {
  background: #94bf1e;
  color: #fff;
  padding: 7px;
  border-radius: 15px;
  font-size: 14px;
  display: inline-block;
  margin-right: 5px;
  text-decoration: none;
  text-transform: uppercase;
  margin-bottom: 3px;
}

div.tagUnselected {
  background: #aaa;
}

div.tagSelected {
  outline: 2px #94bf1e;
}

div.userTag {
  background: #aaa;
  color: #fff;
  padding: 7px;
  border-radius: 15px;
  font-size: 14px;
  display: inline-block;
  margin-right: 5px;
  text-decoration: none;
  margin-bottom: 3px;
}

textarea {
  background: #eee;
  border: none;
  width: calc(100% - 30px);
  font-family: Arial;
  border-radius: 10px;
  padding: 10px;
  color: #555;
  resize: none;
  outline: none;
  height: 75px;
}

.placeholderBox {
   margin-bottom: 5px;
}

.placeholderBox textarea {
     width: calc(100% - 40px);
     font-size: 16px;
     background: #eee;
     padding: 10px;
     padding-left: 20px;
     border-radius: 25px;
     outline: none;
     border: none;
     height: 23px;
     padding-top: 15px;
 }

.editBox input {
   width: calc(100% - 30px);
   font-size: 16px;
   background: #eee;
   padding: 10px;
   padding-left: 20px;
   border-radius: 25px;
   outline: none;
   border: none;
   height: 30px;
}

.loginBox {
  max-width: 500px;
  margin-top: 30px;
  padding-top: 30px;
}

input.large {
   width: calc(100% - 30px);
   font-size: 16px;
   background: #eee;
   padding: 10px;
   padding-left: 20px;
   border-radius: 25px;
   outline: none;
   border: none;
   height: 30px;
}

.editBox textarea {
  height: 100px;
}

.description {
  font-weight: normal;
  display: inline-block;
}

.promptHeader {
  padding-bottom: 5px;
  margin-bottom: 5px;
  /*box-shadow: 0px 0px 10px #d66516;*/
}

.promptTitle {
  font-size: 30px;
  font-weight: bold;
  display: inline-block;
  width: 60%;
}

.promptStats {
  display: inline-block;
  text-align: right;
  width: 40%;
  color: #555;
}

div.followUp {
  position: fixed;
  bottom: 0px;
  box-shadow: 0px 0px 10px #eee;
  clip-path: inset(-10px 0px 0px 0px);
  width: 1000px;
  padding-top: 15px;
  padding-bottom: 15px;
  background: #fafafa;
  max-width: 1000px;
}

input.followUp {
    width: calc(100% - 160px);
    margin-right: 10px;
    font-size: 16px;
    background: #eee;
    padding: 10px;
    padding-left: 20px;
    border-radius: 25px;
    outline: none;
    border: none;
    height: 30px;
}

textarea.followUp {
    width: calc(100% - 160px);
    margin-right: 10px;
    font-size: 16px;
    background: #eee;
    padding-left: 20px;
    border-radius: 25px;
    outline: none;
    border: none;
    height: 30px;
    resize: none;
    color: #000;
    vertical-align: bottom;
    padding-top: 15px;
    height: 23px;
}

textarea.followUpReduced {
  width: calc(100% - 225px);
}

button {
  background: #d66516;
  color: #fff;
  border: none;
  border-radius: 25px;
  padding: 15px;
  font-size: 15px;
  min-width: 100px;
  font-weight: bold;
  -webkit-transition: background-color 0.4s ease-out;
  -moz-transition: background-color 0.4s ease-out;
  -o-transition: background-color 0.4s ease-out;
  transition: background-color 0.4s ease-out;
  cursor: pointer;
}

button:hover {
  background: #94bf1e;
}

.header {
  position: fixed;
  padding-left: 15px;
  border-bottom: 1px solid #eee;
  width: calc(100% - 15px);
  z-index: 9999;
  background: #0e142e;
  color: #fff;
  font-weight: bold;
  height: 57px;
}

.header button {
  padding: 5px;
  margin: 15px;
  margin-left: 0px;
  padding-left: 10px;
  padding-right: 10px;
}

.headerLeft {
  display: inline-block;
  width: 20%;
}

.headerRight {
  display: inline-block;
  width: 80%;
  text-align: right;
}

.messageBox {
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  padding-top: 10px;
  color: #555;
  font-size: 14px;
  line-height: 2;
}

.bottomUp {
  /*display: flex;
  flex-direction: column-reverse;
  height: calc(100% + 70px);
  overflow: scroll;*/
}

.messageContent {
  width: calc(100% - 50px);
  display: inline-block;
  margin-left: 10px;
  vertical-align: top;
}

.messageContentCut {
  width: calc(100% - 100px);
}

.actionButton {
  width: 100%;
  text-align: right;
  margin-top: 10px;
}

.messageIcon {
  width: 40px;
  height: 30px;
  background: #0e142e;
  color: #fff;
  border-radius: 25px;
  font-size: 20px;
  text-align: center;
  display: inline-block;
  font-weight: bold;
  padding-top: 10px;
  line-height: 1;
}

.botIcon {
  background: linear-gradient(45deg, #d66516 0%, #94bf1e 100%);
}

a.clear {
  color: inherit;
  text-decoration: none;
}

a.black {
  color: #000;
  cursor: pointer;
  text-decoration: underline;
}

.slogan {
    font-size: 30px;
    margin-top: 10px;
    margin-bottom: 35px;
}

.smallSlogan {
  font-size: 15px;
  color: #555;
}

button.small {
  margin-left: 10px;
  padding: 10px;
  min-width: 0px;
}

button.plain {
  padding: 10px;
  min-width: 0px;
}

input.left {
  width: 50%;
}

span.message {
  color: #555;
  font-weight: bold;
  margin-left: 10px;
}

span.plainMessage {
  color: #999;
  font-weight: normal;
  margin-left: 10px;
  font-size: 14px;
  vertical-align: super;
}


p {
  margin: 0px;
}

.tab {
  display: inline-block;
  background: #eee;
  padding: 10px;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 3px;
  font-size: 14px;
  text-transform: uppercase;
}

.selected {
  background: #ccc;
}

table, th, td {
  border-collapse: collapse;
  font-size: 14px;
}

table {
  width: 100%;
  border-bottom: 1px solid #ddd;
}

tr {
  border-top: 1px solid #ddd;
}

th {
  text-align: left;
  color: #999;
  font-weight: normal;
}

td, th {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
}

table tr:nth-child(odd) {
  background-color: #eee;
}

.clickable {
  cursor: pointer;
}

.clickable:hover {
  opacity: 0.65;
}

label {
  font-weight: bold;
  display: block;
  margin-left: 20px;
  margin-bottom: 5px;
  margin-top: 15px;
}

select {
   width: 100%;
   font-size: 16px;
   background: #eee;
   padding: 10px;
   padding-left: 20px;
   border-radius: 25px;
   outline: none;
   border: none;
   height: 50px;
   border-right: 15px solid #eee;
   cursor: pointer;
}

input.disabled {
  background: #ddd;
}

hr {
  border: 1px solid #ccc;
  margin-top: 20px;
  margin-bottom: 10px;
}

.error {
  margin-left: 20px;
  font-weight: bold;
  margin-top: 5px;
  color: darkred;
}

.note {
  background: #ffe9e9;
  border-left: 3px solid #d71717;
  padding: 25px;
  width: calc(100% - 53px);
  color: #555;
  font-weight: bold;
}

button.disabled {
  opacity: 0.5;
}

button.disabled:hover {
  background: #d66516;
}

a.subtle {
  color: #999;
  font-size: 13px;
}

input.checkbox {
    height: 18px;
    width: 18px;
    vertical-align: sub;
}

button.hamburger {
    width: 46px;
    height: 45px;
    min-width: 0px;
    margin-top: -9px;
    padding: 10px;
    margin-bottom: -9px;
}

button.hamburgerOpen {
  /*border-radius: 25px 25px 0px 0px;*/
  background: #94bf1e;
}

.menu {
    position: fixed;
    right: 0px;
    z-index: 100000;
    background: #0e142e;
    top: 57px;
    font-weight: bold;
    height: calc(100% - 57px);
    width: 300px;
    /*box-shadow: 0px 0px 10px #aaa;
    clip-path: inset(0px 0px 0px -10px);*/
    padding: 20px;
    color: #fff;
}

.menuBottom {
  position: absolute;
  bottom: 60px;
}

.menu a {
  color: #fff;
  text-decoration: none;
}

.menu a:hover {
  color: #fff;
}

.menu hr {
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #fff;
}

.hidden {
  display: none;
}

.info {
  color: #999;
  font-style: italic;
  margin-left: 20px;
  display: inline-block;
}

.hint {
  color: #999;
  font-style: italic;
  display: inline-block;
}

.explanation {
  padding-left: 10px;
  margin-top: 10px;
  margin-bottom: 30px;
  border-left: 5px solid transparent;
  border-image: linear-gradient(0turn, #d66516, #94bf1e);
  border-image-slice: 1;
  color: #555;
}

.example {
  font-family: monospace;
  margin: 10px;
  font-size: 14px;
  color: #000;
  max-width: 600px;
}

input.select {
   font-size: 16px;
   background: #eee;
   padding: 10px;
   padding-left: 20px;
   border-radius: 25px;
   outline: none;
   border: none;
   height: 30px;
   width: 300px;
}

div.selectRow {
  padding: 10px;
  padding-right: 0px;
  padding-left: 20px;
  text-decoration: underline;
  cursor: pointer;
  display: inline-block;
}

div.selectRow:hover {
  opacity: 0.7;
}

div.selectLoading {
  padding: 10px;
  padding-right: 0px;
  padding-left: 20px;
  display: inline-block;
}

.selectSelected {
  margin-left: 20px;
}

.shifted {
  margin-left: 20px;
}

@media only screen and (max-width: 1000px) {
  .box {
    width: calc(100% - 20px);
    padding: 10px;
    box-shadow: none;
    padding-top: 70px;
    padding-bottom: 50px;
  }

  div.followUp {
    width: calc(100% - 20px);
  }
}

@media only screen and (max-width: 600px) {
  .box {
    width: calc(100% - 20px);
    padding: 10px;
    box-shadow: none;
    padding-top: 70px;
    padding-bottom: 50px;
  }

  div.followUp {
    width: calc(100% - 20px);
  }

  .thumb {
    width: calc(100% - 20px);
  }
}

.fullHeight {
  height: calc(100vh - 200px);
}

.spinnerBox {
  color: #999;
}

.spinner {
  animation-name: spin;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  font-size: 35px;
  margin-top: -0px;
  vertical-align: middle;
  color: green;
  margin-right: 10px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
    color: #d66516;
  }
  to {
    transform: rotate(1080deg);
    color: #94bf1e;
  }
}

.plainSpinner {
  animation-name: plainSpin;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes plainSpin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.recorder {
  height: 45px;
  width: 45px;
  min-width: 0px;
  font-size: 20px;
  background: red;
  padding: 0px;
  text-align: center;
  padding-top: 5px;
}

.recorder:hover {
  opacity: 0.8;
  background: red;
}

.recorderSmall {
    font-size: 19px;
    vertical-align: top;
    margin-right: 10px;
    margin-left: 5px;
}

.recording {
  animation-name: glow;
  animation-duration: 700ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: alternate;
}


@keyframes glow {
  from {
    /*box-shadow: 0px 0px 10px red;*/
    outline: 3px solid white;
  }
  to {
    /*box-shadow: 0px 0px 10px white;*/
    outline: 3px solid red;
  }
}

a.boldButton {
  display: inline-block;
  margin-top: 10px;
  text-decoration: none;
  background: linear-gradient(45deg, #d66516 0%, #94bf1e 100%);
  color: #fff;
  font-weight: bold;
  padding: 10px;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 15px;
  box-shadow: 2px 2px 2px #aaa;
}

a.boldButton:hover {
  opacity: 0.8;
}

.centerCampaign {
   color: #ccc;
   font-size: 30px;
   margin: auto;
   font-weight: bold;
}

div.copy {
    background: #011627;
    margin-top: -10px;
    text-align: right;
}

button.copy {
    background: none;
    color: #fff;
    padding: 5px;
    border: 2px solid #fff;
    margin: 5px;
    min-width: 30px;
    font-size: 12px;
}

button.newSession {
    margin-right: 18px;
    font-size: 12px;
    padding: 8px;
    min-width: 0px;
    border: 1px solid #d66516;
    background: none;
    color: #d66516;
}


button.newSession:hover {
    background: #d66516;
    color: #fff;
}